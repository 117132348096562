<template>
  <div id="Exceed">
    <!-- 基础信息 -->
    <app-tree @select="onTreeSelect"></app-tree>
    <div class="content" ref="container">
      <a-affix :target="() => this.$refs.container">
        <div class="title-wrapper">
          <span v-if="parentTitle">{{ parentTitle }}</span
          >-<span v-if="title">{{ title }}</span>
        </div>
      </a-affix>
      <div>
        <a-collapse
          v-model="activeKey"
          :expand-icon-position="expandIconPosition"
          :bordered="false"
        >
          <!-- 企业信息 -->
          <a-collapse-panel key="enterprise">
            <big-title slot="header" title="企业信息"></big-title>
            <div class="item-wrapper">
              <div class="key">企业名称</div>
              <div v-if="enterprise" class="value">
                {{ enterprise.EnterpriseMC || "" }}
              </div>
            </div>
            <div class="item-wrapper">
              <div class="key">行业类型</div>
              <div v-if="enterprise" class="value">
                {{ enterprise.Industrytype || "" }}
              </div>
            </div>
            <div class="item-wrapper">
              <div class="key">企业地址</div>
              <div v-if="enterprise" class="value">
                {{ enterprise.EnterpriseDZ || "" }}
              </div>
            </div>
            <div class="item-wrapper">
              <div class="key">环保联系人</div>
              <div v-if="enterprise" class="value">
                {{ enterprise.Econtact || "" }}
              </div>
            </div>
            <div class="item-wrapper">
              <div class="key">固定电话</div>
              <div v-if="enterprise" class="value">
                {{ enterprise.telephone || "" }}
              </div>
            </div>
            <div class="item-wrapper">
              <div class="key">电子邮箱</div>
              <div v-if="enterprise" class="value">
                {{ enterprise.EnterpriseDZ || "" }}
              </div>
            </div>
            <div class="item-wrapper">
              <div class="key">移动电话</div>
              <div v-if="enterprise" class="value">{{ enterprise.phone }}</div>
            </div>
            <div class="item-wrapper">
              <div class="key">传真号码</div>
              <div v-if="enterprise" class="value">{{ enterprise.Fax }}</div>
            </div>
          </a-collapse-panel>
          <!-- 监控基站信息 -->
          <a-collapse-panel v-if="baseShow" key="baseStation">
            <big-title slot="header" title="监控基站信息"></big-title>
            <div class="item-wrapper">
              <div class="key">基站名称</div>
              <div class="value">
                {{ baseStation.station_name }}
              </div>
            </div>
            <div class="item-wrapper">
              <div class="key">基站经度</div>
              <div class="value">
                {{ baseStation.station_long }}
              </div>
            </div>
            <div class="item-wrapper">
              <div class="key">基站纬度</div>
              <div class="value">
                {{ baseStation.station_lat }}
              </div>
            </div>
            <div class="item-wrapper">
              <div class="key">排口类型</div>
              <div class="value">
                {{ baseStation.discharge_type }}
              </div>
            </div>
            <div class="item-wrapper">
              <div class="key">MN识别号</div>
              <div class="value">{{ baseStation.MN_number }}</div>
            </div>

            <div class="item-wrapper">
              <div class="key">内网IP</div>
              <div class="value">
                {{ baseStation.Intranet_IP }}
              </div>
            </div>
            <div class="item-wrapper">
              <div class="key">公网IP</div>
              <div class="value">{{ baseStation.Public_IP }}</div>
            </div>
            <div class="item-wrapper">
              <div class="key">内网网关</div>
              <div class="value">
                {{ baseStation.Intranet_gateway }}
              </div>
            </div>
            <div class="item-wrapper">
              <div class="key">公网网关</div>
              <div class="value">
                {{ baseStation.Public_gateway }}
              </div>
            </div>

            <div class="item-wrapper">
              <div class="key">公网子网掩码</div>
              <div class="value">
                {{ baseStation.Network_mask }}
              </div>
            </div>
            <div class="item-wrapper">
              <div class="key">监测点位</div>
              <div class="value">
                {{ baseStation.Monitoring_point }}
              </div>
            </div>
            <div class="item-wrapper">
              <div class="key">监测点当量直径</div>
              <div class="value">
                {{ baseStation.monitor_diameter }}
              </div>
            </div>
            <div class="item-wrapper">
              <div class="key">测点截面积</div>
              <div class="value">
                {{ baseStation.sectional_area }}
              </div>
            </div>
            <div class="item-wrapper">
              <div class="key">标准过剩系数</div>
              <div class="value">{{ baseStation.excess }}</div>
            </div>
            <div class="item-wrapper">
              <div class="key">直管段长度</div>
              <div class="value">{{ baseStation.straight }}</div>
            </div>

            <div class="item-wrapper">
              <div class="key">监测点照片</div>
              <div class="value">
                <!-- 不懂，暂时这么写？？？？{{
                                    baseStation.monitor_photo
                                }} -->
                <!-- <img v-if="baseStation.monitor_photo" :src="getImgUrl(baseStation.monitor_photo)" alt=""> -->
                <img
                  v-if="baseStation.monitor_photo"
                  :src="baseStation.monitor_photo"
                  alt=""
                />
              </div>
            </div>

            <div class="item-wrapper">
              <div class="key">取样管线长度</div>
              <div class="value">
                {{ baseStation.sampling_len }}
              </div>
            </div>
            <div class="item-wrapper">
              <div class="key">现场勘查人</div>
              <div class="value">{{ baseStation.survey }}</div>
            </div>
            <div class="item-wrapper">
              <div class="key">方案编写人</div>
              <div class="value">{{ baseStation.writer }}</div>
            </div>
            <div class="item-wrapper">
              <div class="key">设备安装调试时间</div>
              <div class="value">{{ baseStation.SBAZTSSJ }}</div>
            </div>

            <div class="item-wrapper">
              <div class="key">安装人员</div>
              <div class="value">{{ baseStation.Installer }}</div>
            </div>
            <div class="item-wrapper">
              <div class="key">调试人员</div>
              <div class="value">{{ baseStation.Debugger }}</div>
            </div>
            <div class="item-wrapper">
              <div class="key">比对监测部门</div>
              <div class="value">
                {{ baseStation.comparison }}
              </div>
            </div>
            <div class="item-wrapper">
              <div class="key">监测日期</div>
              <div class="value">
                {{ baseStation.MonitorDate }}
              </div>
            </div>
            <div class="item-wrapper">
              <div class="key">验收日期</div>
              <div class="value">
                {{ baseStation.AcceptanceDate }}
              </div>
            </div>
          </a-collapse-panel>
          <!-- 监测设备信息 -->
          <a-collapse-panel v-if="baseShow" key="equipment">
            <big-title slot="header" title="监测设备信息"></big-title>
            <div class="item-wrapper">
              <div class="key">监测设备厂家</div>
              <div class="value">
                {{ equipment.Monitor_facturer || "" }}
              </div>
            </div>
            <div class="item-wrapper">
              <div class="key">设备型号</div>
              <div class="value">{{ equipment.unit_type }}</div>
            </div>
            <div class="item-wrapper">
              <div class="key">设备生产日期</div>
              <div class="value">{{ equipment.SBSCRQ }}</div>
            </div>
            <div class="item-wrapper">
              <div class="key">采样方式</div>
              <div class="value">{{ equipment.sample }}</div>
            </div>
            <div class="item-wrapper">
              <div class="key">测量方式</div>
              <div class="value">{{ equipment.Method }}</div>
            </div>

            <div class="item-wrapper">
              <div class="key">CMC认证</div>
              <div class="value">{{ equipment.CMC }}</div>
            </div>
            <div class="item-wrapper">
              <div class="key">CCEP认证</div>
              <div class="value">{{ equipment.CCEP }}</div>
            </div>
            <div class="item-wrapper">
              <div class="key">适用性检测报告</div>
              <div class="value">{{ equipment.report }}</div>
            </div>

            <a-table
              :columns="columns"
              :data-source="data"
              bordered
              :pagination="false"
              size="small"
              :scroll="{ x: '800px', y: 0 }"
              :loading="loading"
            >
            </a-table>
          </a-collapse-panel>
          <!-- 排口设置 -->
          <a-collapse-panel v-if="baseShow" key="outlet">
            <big-title slot="header" title="排口设置"></big-title>
            <div v-if="outlet.station_Type == 1">
              <div class="item-wrapper">
                <div class="key">燃料类型</div>
                <div class="value">
                  {{ outlet.Monitor_facturer }}
                </div>
              </div>
              <div class="item-wrapper">
                <div class="key">锅炉类型</div>
                <div class="value">{{ outlet.unit_type }}</div>
              </div>
              <div class="item-wrapper">
                <div class="key">锅炉容量</div>
                <div class="value">{{ outlet.SBSCRQ }}</div>
              </div>
              <div class="item-wrapper">
                <div class="key">额定量大风量</div>
                <div class="value">{{ outlet.sample }}</div>
              </div>
              <div class="item-wrapper">
                <div class="key">最大发电量</div>
                <div class="value">{{ outlet.Method }}</div>
              </div>

              <div class="item-wrapper item-wrapper-big-border">
                <div class="key">脱硝方式</div>
                <div class="value">
                  {{ outlet.Denitrification }}
                </div>
              </div>
              <div class="item-wrapper">
                <div class="key">投运时间</div>
                <div class="value">{{ outlet.DTYSJ }}</div>
              </div>
              <div class="item-wrapper">
                <div class="key">脱硝效率</div>
                <div class="value">
                  {{ outlet.Defficiency }}
                </div>
              </div>

              <div class="item-wrapper item-wrapper-big-border">
                <div class="key">除尘方式</div>
                <div class="value">{{ outlet.Dedusting }}</div>
              </div>
              <div class="item-wrapper">
                <div class="key">投运时间</div>
                <div class="value">{{ outlet.CTYSJ }}</div>
              </div>
              <div class="item-wrapper">
                <div class="key">除尘效率</div>
                <div class="value">
                  {{ outlet.Cefficiency }}
                </div>
              </div>

              <div class="item-wrapper item-wrapper-big-border">
                <div class="key">脱硫方式</div>
                <div class="value">
                  {{ outlet.Desulphurization }}
                </div>
              </div>
              <div class="item-wrapper">
                <div class="key">投运时间</div>
                <div class="value">{{ outlet.LTYSJ }}</div>
              </div>
              <div class="item-wrapper">
                <div class="key">脱硫效率</div>
                <div class="value">
                  {{ outlet.Lefficiency }}
                </div>
              </div>

              <div class="item-wrapper item-wrapper-big-border">
                <div class="key">超低除尘方式</div>
                <div class="value">{{ outlet.CDedusting }}</div>
              </div>
              <div class="item-wrapper">
                <div class="key">投运时间</div>
                <div class="value">{{ outlet.CCTYSJ }}</div>
              </div>
              <div class="item-wrapper">
                <div class="key">除尘效率</div>
                <div class="value">
                  {{ outlet.CCefficiency }}
                </div>
              </div>
            </div>
            <div v-if="outlet.station_Type == 2">
              <div class="item-wrapper">
                <div class="key">处理工艺</div>
                <div class="value">{{ outlet.process }}</div>
              </div>
              <div class="item-wrapper">
                <div class="key">日处理量</div>
                <div class="value">{{ outlet.Daily }}</div>
              </div>
              <div class="item-wrapper">
                <div class="key">排放间隔</div>
                <div class="value">{{ outlet.interval }}</div>
              </div>
              <div class="item-wrapper">
                <div class="key">除尘效率</div>
                <div class="value">
                  {{ outlet.SFHLZ == "0" ? "否" : "是" }}
                </div>
              </div>
            </div>
          </a-collapse-panel>
        </a-collapse>
      </div>
    </div>
  </div>
</template>

<script>
import AppTree from "@/components/AppTree.vue";
import BigTitle from "@/components/public/BigTitle.vue";
import { http } from "@/utils/Http.js";

const columns = [
  {
    align: "center",
    width: 100,
    dataIndex: "ItemCode",
  },
  {
    title: "编码",
    align: "center",
    width: 100,
    dataIndex: "SC",
  },
  {
    title: "测量原理",
    align: "center",
    width: 100,
    dataIndex: "JKYL",
  },
  {
    title: "检出限",
    align: "center",
    width: 100,
    dataIndex: "JCX",
  },
  {
    title: "异常最小值",
    align: "center",
    width: 100,
    dataIndex: "YCZXZ",
  },
  {
    title: "异常最大值",
    align: "center",
    width: 100,
    dataIndex: "YCZDZ",
  },
  {
    title: "量程最小值",
    align: "center",
    width: 100,
    dataIndex: "JCLCZX",
  },
  {
    title: "量程最大值",
    align: "center",
    width: 100,
    dataIndex: "JCLCZD",
  },
  {
    title: "生产厂家",
    align: "center",
    width: 100,
    dataIndex: "SCCJ",
  },
  {
    title: "排放标准",
    align: "center",
    width: 100,
    dataIndex: "PFBZ",
  },
  {
    title: "冬季限值",
    align: "center",
    width: 100,
    dataIndex: "DJXZ",
  },
  {
    title: "超标预警值",
    align: "center",
    width: 100,
    dataIndex: "YJXZ",
  },
];

export default {
  name: "home-appbaseics-page",
  components: {
    AppTree,
    BigTitle,
  },
  data() {
    return {
      title: "全国",
      activeKey: ["enterprise"],
      expandIconPosition: "right",
      // 企业信息
      enterprise: {},
      //监控基站信息
      baseStation: {},
      // 监测设备信息
      equipment: {},
      data: [],
      loading: false,
      columns,
      // 排口设置
      outlet: {},

      parentTitle: "",
      title: "",

      baseShow: false,
    };
  },
  mounted() {
    let me = this;

    // //企业编号
    // console.log(this.$store.state.expandedKeys);

    // //基站编号
    // console.log(this.$store.state.selectedKeys);
    // console.log(me.$store.state.selectedData);
    // let item = me.$store.state.selectedData;
    // let station_Type = item.station_Type,
    //   station_bh = item.id,
    //   EnterpriseBH = item.parentId;

    // me.getAjax(station_Type, station_bh, EnterpriseBH); // 获取企业信息
  },
  methods: {
    //选择时效性
    onTreeSelect(item) {
      console.log("树的选择信息：", item);
      let me = this;

      if (item.Lx == 6) {
        me.baseShow = true;
        me.parentTitle = this.$store.state.parentData.name;
        me.title = this.$store.state.selectedData.name;

        if (!item.leaf) {
          return;
        }

        let station_Type = item.station_Type,
          station_bh = item.id,
          EnterpriseBH = item.parentId;
        me.getAjax(station_Type, station_bh, EnterpriseBH); // 获取企业信息
      }

      if (item.Lx == 5) {
        me.baseShow = false;
        me.parentTitle = this.$store.state.parentData.name;
        me.title = this.$store.state.selectedData.name;

        if (!item.leaf) {
          return;
        }

        let station_Type = item.station_Type,
          station_bh = "",
          EnterpriseBH = item.id;
        me.getAjax(station_Type, station_bh, EnterpriseBH); // 获取企业信息
      }

      console.log(me.baseShow);
    },
    computed() {
      return (url) => {
        if (!url) {
          return;
        }
        http({
          url: `/data/ShowImage.ashx?guid=${url}`,
          data: params,
          success(obj) {
            console.log(obj);
            return obj.Data[0];
          },
          failure(obj) {},
        });
      };
    },

    /**
     * 请求ajax获取数据
     */
    getAjax(station_Type, station_bh, EnterpriseBH) {
      let me = this;
      var params = {
        CKM: this.$store.state.loggedIn.CKM,
        EnterpriseBH,
      };
      this.loading = true;

      //   console.log(station_Type);
      //   console.log(station_bh);
      //   console.log(EnterpriseBH);

      http({
        url: "/data/HJJC_ENTERPRISEDETAILS.aspx",
        data: params,
        success(obj) {
          console.log(obj);
          if (obj.Data && obj.Data.length > 0) {
            me.enterprise = obj.Data[0];
          }
        },
        failure(obj) {},
      });

      if (station_bh) {
        http({
          url: "/data/q30/HJJC_STATION_Type_Data.aspx",
          data: {
            CKM: me.$store.state.loggedIn.CKM,
            station_bh,
            station_Type,
            EnterpriseBH,
          },
          success(obj) {
            console.log(obj);
            // 监控基站信息
            if (obj.Data && obj.Data.length > 0) {
              me.baseStation = obj.Data[0];
            }
            // 监测设备信息
            if (obj.Data1 && obj.Data1.length > 0) {
              me.equipment = obj.Data1[0];
            }
            // 监测设备信息的表格 暂时没写

            me.loading = false;

            if (obj.Data2 && obj.Data2.length > 0) {
              me.data = obj.Data2;
            }
            // 排口设置
            if (obj.Data && obj.Data.length > 0) {
              let Data3 = {};
              if (obj.Data3 && obj.Data3.length > 0) {
                Data3 = obj.Data3[0];
              }

              if (obj.Data[0].ItemKind == 1) {
                //废气
                Data3.station_Type = 1;
              }
              if (obj.Data[0].ItemKind == 2) {
                //废水
                Data3.station_Type = 2;
              }

              me.outlet = Data3;
            }
          },
          failure(obj) {
            me.loading = false;
          },
        });
      }
    },
  },
  watch: {
    activeKey(key) {
      // console.log(key);
    },
  },

  destoryed() {
    this.loading = false;
  },
};
</script>

<style lang="scss" scoped>
#Exceed {
  width: 1200px;
  margin: 0 auto;
  display: flex;
  padding-top: 30px;
  height: 100%;

  .tree {
    width: 248px;
    height: 100%;
    padding: 20px 10px 0 0;
    margin: 0 10px 0 0;
    overflow: hidden;
    overflow-y: scroll;
    overflow-x: hidden;
    background-color: #ffffff;

    #tree {
      overflow: hidden;
    }
  }

  .tree::-webkit-scrollbar {
    width: 5px;
    background-color: #d9d9d9;
  }

  .tree::-webkit-scrollbar-thumb {
    height: 30px;
    background-color: #52c41a;
  }

  // 消除滚动条样式
  .content::-webkit-scrollbar {
    display: none;
  }
  .content {
    flex: 1;
    overflow: hidden;
    overflow-y: auto;
    background-color: #ffffff;
    padding: 20px 20px 0px 20px;

    .ant-collapse-borderless {
      background-color: #fff;
    }

    .item-wrapper-big-border {
      border-top: 4px solid #1691ff !important;
      border-radius: 5px;
    }

    .title-wrapper {
      padding: 0px 0px 12px 0px;
      color: #181818;
      font-size: 18px;
      font-weight: bold;
      text-align: center;
      background-color: #fff;
    }
    .item-wrapper {
      display: flex;
      border-top: 1px dashed #d1d1d1;
      height: 40px;
      align-items: center;
      .key {
        width: 130px;
        padding-left: 16px;
        font-size: 14px;
        color: #3f3f3f;
      }
      .value {
        flex: 1;
        color: #181818;
        font-size: 14px;
      }
    }
  }
}
</style>
