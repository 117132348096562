<template>
    <div class="big-title">
        {{title}}
    </div>
</template>
<script>
export default {
    name:"big-title-module",
    props:{
        title:{
            type:String,
            default(){
                return ""
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.big-title{
    color: #181818;
    font-size: 16px;
    line-height: 22px;
    height: 22px;
    padding-left: 12px;
    border-left: 4px solid #027AFF;
}
</style>